import React from 'react'

import { GiftRegistryPage } from '../components'

class CadeauLijsten extends React.Component {
  render() {
    return <GiftRegistryPage />
  }
}

export default CadeauLijsten
